<template>
  <div class="field">
    <datepicker
      ref="picker"
      :key="name"
      v-model="date"
      :disabled="isStatic"
      :disabled-dates="disabledDates"
      :format="formatDate"
      :input-class="{
        input: true,
        'is-danger': error,
        'is-static': isStatic,
        'placeholder-not-shown': !hasValue
      }"
      :language="fi"
      :monday-first="true"
      :name="name"
      :placeholder="placeholder"
      :value="date"
      :inline="true"
      wrapper-class="control has-float-label"
    >
      <label
        slot="afterDateInput"
        :for="name"
        class="label"
        style="z-index: 1000;"
        @click="openCalendar"
      >
        {{ label }}
      </label>
    </datepicker>
    <transition name="slideDown">
      <p v-if="error" class="help is-danger">
        {{ error }}
      </p>
    </transition>
  </div>
</template>

<script>
// @todo beforeCalendarHeader slot
import fi from 'vuejs-datepicker/dist/locale/translations/fi'
import { format, parse } from 'date-fns'

export default {
  $_veeValidate: {
    value() {
      return this.value
    },
    name() {
      return this.name
    },
    alias() {
      return this.label || this.placeholder || this.name
    },
    events: 'input'
  },
  components: {
    Datepicker: () => import('vuejs-datepicker')
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ' '
    },
    tooltip: {
      type: String,
      default: ''
    },
    header: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Date],
      default: null
    },
    error: {
      type: String,
      default: ''
    },
    isStatic: {
      type: Boolean,
      default: false
    },
    disabledDates: {
      type: Object,
      default() {
        return {
          to: new Date()
        }
      }
    },
    format: {
      type: String,
      default: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    }
  },
  data() {
    return {
      fi: fi,
      date: null
    }
  },
  computed: {
    hasValue() {
      return this.value !== ''
    }
  },
  watch: {
    date() {
      this.$emit('input', this.formatDate(this.date))
    },
    value(value) {
      this.date = parse(value, this.format, new Date())
    }
  },
  mounted() {
    if (this.value) {
      this.date = parse(this.value, this.format, new Date())
    }
  },
  methods: {
    openCalendar() {
      if (!this.isStatic) this.$refs.picker.showCalendar()
    },
    formatDate(date) {
      return format(date, this.format)
    }
  }
}
</script>

<style lang="scss">
.vdp-datepicker__calendar {
  border-radius: $control-radius !important;
  .cell {
    &:not(.blank):not(.disabled) {
      &.day:hover,
      &.month:hover,
      &.year:hover {
        border: 1px solid $primary !important;
        border-radius: $control-radius !important;
      }
    }
    &.selected {
      background: $primary !important;
      border-radius: $control-radius !important;
      color: $white !important;
      &:hover,
      &.highlighted {
        background: $primary !important;
        border-radius: $control-radius !important;
      }
    }
  }
}

.vdp-datepicker {
  input.is-static {
    color: $text;
    cursor: text;
  }
}
.modal-card {
  .vdp-datepicker__calendar {
    width: 100%;
    .cell {
      font-size: 0.8em;
    }
  }
}
</style>
