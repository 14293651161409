<template>
  <div class="box" style="max-width: 500px; width: 100%;">
    <div class="content">
      <h1 class="has-text-primary is-uppercase">Kirjaudu</h1>
    </div>
    <form v-if="data.submitted === false" @submit.prevent>
      <form-switch
        key="data.register"
        v-model="data.register"
        v-validate="'required'"
        :error="errors.first('register')"
        :labels="['Kirjaudu', 'Uusi käyttäjä']"
        name="register"
      />
      <form-input
        key="form.email"
        v-model="form.email"
        v-validate="'required|email'"
        :error="errors.first('email')"
        type="email"
        name="email"
        label="Sähköposti"
      />
      <form-input
        key="form.password"
        v-model="form.password"
        v-validate="'required|max:128'"
        :error="errors.first('password')"
        type="password"
        name="password"
        label="Salasana"
      />
      <form-input
        v-if="data.register"
        key="form.password_confirmation"
        v-model="form.password_confirmation"
        v-validate="'required|confirmed:password|max:128'"
        :error="errors.first('password_confirmation')"
        type="password"
        name="password_confirmation"
        label="Salasana uudelleen"
      />
      <button
        :disabled="data.submitDisabled || data.submitLoading"
        class="button is-primary is-right"
        type="button"
        style="float: right; padding: 15px 30px;"
        @click="submit"
      >
        <span>Lähetä</span>
        <span v-if="data.submitLoading" class="icon">
          <font-awesome-icon :icon="['fas', 'spinner-third']" spin />
        </span>
      </button>
    </form>
    <div v-else-if="data.register === true" class="content">
      Registeröinti onnistui, pyydä järjestelmävalvioo aktivoimaan tilisi niin
      voit kirjautua.
    </div>
    <div v-else class="content">
      Kirjautuminen onnistui!
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import formInput from '~/components/form/input'
import formSwitch from '~/components/form/switch'
import serverFieldErrors from '~/mixins/serverFieldErrors'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formInput,
    formSwitch
  },
  mixins: [serverFieldErrors],
  data() {
    return {
      data: {
        applicationError: '',
        register: false,
        submitDisabled: false,
        submitLoading: false,
        submitted: false
      },
      form: {
        email: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  methods: {
    async submit() {
      try {
        const action = this.data.register ? 'register' : 'login'
        const result = await this.$store.dispatch(action, this.form)
        if (_.has(result, 'errors')) {
          this.handleFieldError(result.errors)
        }
      } catch (e) {
        this.data.applicationError = e.message
      }
      this.data.submitted = true
      setTimeout(() => {
        this.$router.push('/')
      }, 1000)
    }
  }
}
</script>
