<template>
  <div class="field has-addons is-switch">
    <div class="control is-expanded">
      <label
        v-if="label"
        :for="name"
        :class="{ 'is-as-label': isStatic }"
        class="label"
      >
        {{ label }}
      </label>
      <div v-if="!isStatic" :name="name" class="buttons has-addons">
        <button
          :class="{ 'is-primary': switchValue === false }"
          type="button"
          class="button"
          @click.prevent="changeValue(false)"
        >
          {{ labels[0] }}
        </button>
        <button
          :class="{ 'is-primary': switchValue }"
          type="button"
          class="button"
          @click.prevent="changeValue(true)"
        >
          {{ labels[1] }}
        </button>
      </div>
      <input
        v-else
        :value="switchValue ? 'Kyllä' : 'Ei'"
        class="input is-static has-padding"
        readonly
      />
    </div>
    <transition name="slideDown">
      <p v-if="error" class="help is-danger">{{ error }}</p>
    </transition>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    value() {
      return this.value
    },
    name() {
      return this.name
    },
    alias() {
      return this.label || this.name
    },
    events: 'input'
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [Boolean, String, Number],
      default: null
    },
    error: {
      type: String,
      default: ''
    },
    isStatic: {
      type: Boolean,
      default: false
    },
    bool: {
      type: Boolean,
      default: true
    },
    labels: {
      type: Array,
      default: () => ['Ei', 'Kyllä']
    }
  },
  data() {
    return {
      switchValue: this.getInternalValue()
    }
  },
  watch: {
    value(value) {
      this.switchValue = this.getInternalValue()
    }
  },
  methods: {
    changeValue(value) {
      this.switchValue = value
      this.$emit('input', this.getExternalValue(value))
    },
    getInternalValue(value = this.value) {
      // When value is not set dont set the value
      if (value === null) return null
      if (typeof value === 'boolean') return value
      if (typeof value === 'string') return value === '1' || value === 'true'
      return Boolean(value)
    },
    getExternalValue(value = this.switchValue) {
      if (value === null) return null
      if (this.bool) return value
      return value ? '1' : '0'
    }
  }
}
</script>

<style lang="scss">
.field.is-switch {
  flex-direction: column;
  margin-bottom: 1.5rem;
  label.label {
    margin-bottom: 0;
  }
  .buttons {
    @include tablet {
      flex-wrap: nowrap;
    }
    .button {
      width: 100%;
      @include tablet {
        width: 50%;
      }
    }
  }
}
</style>
