<template>
  <div class="container">
    <section class="section is-small">
      <div class="content">
        <h1 style="font-family: Oswald,sans-serif;">Vuokrapörssi</h1>
      </div>
      <rent-table
        title="Vuokrattavissa olevat asiakkuudet"
        :data="toRent"
        @refresh="refresh"
      />
      <button class="button is-primary" @click="formActive = !formActive">
        Lisää oma ilmoituksesi
      </button>
    </section>
    <section class="section is-small">
      <rent-table
        title="Halutaan vuokrata asiakkuus"
        :data="forRent"
        @refresh="refresh"
      />
      <button class="button is-primary" @click="formActive = !formActive">
        Lisää oma ilmoituksesi
      </button>
    </section>
    <div v-if="$store.state.authUser" class="section is-small">
      <button class="button is-primary" @click="logout">
        Kirjaudu ulos
      </button>
    </div>
    <rent-form
      :active="formActive"
      @close="formActive = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import rentForm from '~/components/form'
import rentTable from '~/components/table'

export default {
  components: {
    rentForm,
    rentTable
  },
  data() {
    return {
      formActive: false,
      toRent: {},
      forRent: {}
    }
  },
  async asyncData({ app, params }) {
    try {
      const result = await app.$axios.$get('/api/advert')
      return { toRent: result.toRent, forRent: result.forRent }
    } catch (error) {
      app.$logger.error(error)
    }
  },
  methods: {
    async refresh() {
      const data = await this.$options.asyncData(this.$root.$options.context)
      for (const k in data) {
        this[k] = data[k]
      }
    },
    async logout() {
      await this.$store.dispatch('logout')
    }
  }
}
</script>
