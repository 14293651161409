<template>
  <div class="field">
    <div
      :class="{
        'has-float-label': label,
        'has-icons-right': icon.length
      }"
      class="control"
    >
      <textarea
        v-if="type === 'textarea'"
        :id="name"
        ref="input"
        :placeholder="placeholder"
        :name="name"
        :value="isStatic ? value || '-' : value"
        :disabled="disabled"
        :class="[
          {
            'is-danger': error,
            'is-static': isStatic,
            'placeholder-not-shown': !hasValue
          },
          inputClass
        ]"
        :readonly="isStatic"
        class="textarea"
        rows="4"
        @keyup.enter="enterAction"
        @change="$emit('change', $event.target.value)"
        @input="$emit('input', $event.target.value)"
      />
      <input
        v-else
        :id="name"
        ref="input"
        :placeholder="placeholder"
        :name="name"
        :value="isStatic ? value || '-' : value"
        :disabled="disabled"
        :class="[
          {
            'is-danger': error,
            'is-static': isStatic,
            'placeholder-not-shown': !hasValue
          },
          inputClass
        ]"
        :type="type"
        :readonly="isStatic"
        class="input"
        @keyup.enter="enterAction"
        @change="$emit('change', $event.target.value)"
        @input="$emit('input', $event.target.value)"
      />
      <label :for="name" class="label">
        {{ label }}
      </label>
      <div v-if="icon.length" class="icon is-small is-right">
        <font-awesome-icon :icon="icon" />
      </div>
    </div>
    <transition name="slideDown">
      <p v-if="error" class="help is-danger">
        {{ error }}
      </p>
    </transition>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    value() {
      return this.value
    },
    name() {
      return this.name
    },
    alias() {
      return this.label || this.placeholder || this.name
    }
  },
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    enterAction: {
      default: () => undefined,
      type: Function
    },
    error: {
      default: '',
      type: String
    },
    icon: {
      default: () => [],
      type: Array
    },
    inputClass: {
      default: '',
      type: String
    },
    isStatic: {
      default: false,
      type: Boolean
    },
    label: {
      default: '',
      type: String
    },
    name: {
      required: true,
      type: String
    },
    placeholder: {
      default: ' ',
      type: String
    },
    type: {
      default: 'text',
      type: String
    },
    value: {
      default: null,
      type: [String, Number]
    }
  },
  computed: {
    hasValue() {
      return this.value !== ''
    }
  }
}
</script>
