<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <login-form></login-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoginForm from '~/components/LoginForm'

export default {
  components: { LoginForm }
}
</script>
