<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Vuokrapörssi
        </p>
      </header>
      <section class="modal-card-body">
        <form v-if="!data.submitted" @submit.prevent>
          <div class="content">
            <p>
              Ilmoituksen täyttämisen jälkeen saat vahvistusviestin
              sähköpostiisi. Samalla tulee myös linkki ilmoituksen poistamiseen,
              joten pidä sähköposti tallessa.
            </p>
          </div>
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <form-input
                    key="form.name"
                    v-model="form.name"
                    v-validate="'required'"
                    :error="errors.first('name')"
                    type="text"
                    name="name"
                    label="Nimi"
                  />
                </div>
                <div class="column">
                  <form-input
                    key="form.phone"
                    v-model="form.phone"
                    v-validate="'required'"
                    :error="errors.first('phone')"
                    type="text"
                    name="phone"
                    label="Puhelinnumero"
                  />
                </div>
                <div class="column">
                  <form-input
                    key="form.email"
                    v-model="form.email"
                    v-validate="'required'"
                    :error="errors.first('email')"
                    type="email"
                    name="email"
                    label="Sähköpostiosoite"
                  />
                </div>
              </div>
              <form-switch
                key="form.type"
                v-model="form.type"
                v-validate="'required'"
                :error="errors.first('type')"
                :bool="false"
                :labels="[
                  'Oman asiakuuden vuokraus',
                  'Haluan vuokrata asiakuuden'
                ]"
                name="type"
              />
              <div class="columns">
                <div class="column">
                  <form-datepicker
                    key="form.from"
                    v-model="form.from"
                    v-validate="'required'"
                    :error="errors.first('from')"
                    name="from"
                    label="Alkaen"
                    placeholder="Alkaen"
                  />
                </div>
                <div class="column">
                  <form-datepicker
                    key="form.to"
                    v-model="form.to"
                    v-validate="'required'"
                    :error="errors.first('to')"
                    name="to"
                    label="Päättyen"
                    placeholder="Päättyen"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column is-4">
                  <form-input
                    key="form.price"
                    v-model="form.price"
                    v-validate="'required'"
                    :error="errors.first('price')"
                    :icon="['fas', 'euro-sign']"
                    type="text"
                    name="price"
                    label="Hinta"
                  />
                </div>
                <div class="column">
                  <form-input
                    key="form.info"
                    v-model="form.info"
                    v-validate="'required'"
                    :error="errors.first('info')"
                    type="textarea"
                    name="info"
                    label="Lisätiedot"
                    placeholder="mm. Jäsenyystyyppi ja muut huomiot"
                  />
                </div>
                <!-- Honeypot -->
                <input
                  v-model="form.address"
                  type="text"
                  style="display: none;"
                />
              </div>
            </div>
          </div>
        </form>
        <!-- Section after advert is stored -->
        <div v-else class="content">
          <p>Kiitos ilmoituksestasi!</p>
          <p>
            Vahvistus on lähetetty sähköpostiisi. Sähköposti sisältää myös
            ilmoituksen poistolinkin.
          </p>
        </div>
        <div v-if="data.applicationError" class="columns">
          <div class="column">
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="notification is-danger"
              v-html="data.applicationError"
            ></div>
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-block">
        <button
          class="button is-white"
          style="padding: 15px 30px;"
          @click="close"
        >
          Sulje
        </button>
        <button
          v-if="!data.submitted"
          :disabled="data.submitDisabled || data.submitLoading"
          class="button is-primary is-right"
          type="button"
          style="float: right; padding: 15px 30px;"
          @click="submit"
        >
          <span>Lähetä</span>
          <span v-if="data.submitLoading" class="icon">
            <font-awesome-icon :icon="['fas', 'spinner-third']" spin />
          </span>
        </button>
      </footer>
    </div>
    <button class="modal-close is-large" @click="close"></button>
  </div>
</template>

<script>
import _ from 'lodash'
import formDatepicker from '~/components/form/datepicker'
import formInput from '~/components/form/input'
import formSwitch from '~/components/form/switch'
import serverFieldErrors from '~/mixins/serverFieldErrors'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formDatepicker,
    formInput,
    formSwitch
  },
  mixins: [serverFieldErrors],
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {
        applicationError: '',
        submitted: false,
        submitDisabled: false,
        submitLoading: false
      },
      form: {
        name: '',
        phone: '',
        email: '',
        type: null,
        to: null,
        from: null,
        price: '',
        info: '',
        address: ''
      }
    }
  },
  methods: {
    /**
     * Handle application submit
     */
    async submit() {
      this.data.submitLoading = true
      const validateResult = await this.$validator.validate()
      // Validator fails
      if (!validateResult) {
        this.showValidatorErrors()
      }
      await this.storeApplication()
      this.data.submitLoading = false
    },
    /**
     * Show validation errors
     */
    showValidatorErrors() {
      this.data.applicationError =
        'Hakemusta ei voida lähettää ennen kuin virheelliset kentät on korjattu.'
      try {
        this.$el
          .querySelector(`[name="${this.$validator.errors.items[0].field}"]`)
          .scrollIntoView()
      } catch (error) {
        this.$logger.error(error, {
          metaData: {
            'Validator errors': this.$validator.errors
          }
        })
      }
    },
    async storeApplication() {
      try {
        const result = await this.$axios.$post('/api/advert', this.form)
        if (_.has(result, 'success')) {
          this.data.submitted = true
          this.data.applicationError = ''
          this.$emit('refresh')
        }
      } catch (error) {
        // Server validation errors
        if (error.response.status === 422) {
          if (_.has(error.response.data, 'errors')) {
            this.handleFieldError(error.response.data.errors)
          }
        } else {
          this.$logger.error(error)
        }
      }
    },
    close() {
      // Reset form
      this.data.submitted = false
      this.form.name = ''
      this.form.phone = ''
      this.form.email = ''
      this.form.type = null
      this.form.to = null
      this.form.from = null
      this.form.price = ''
      this.form.info = ''
      this.form.address = ''
      // Close form
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.field:not(:last-child) {
  margin-bottom: 1.5rem;
}
</style>
