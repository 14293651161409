<template>
  <div class="table-container is-mobile table-rent">
    <p class="title is-size-4">{{ title }}</p>
    <table class="table is-bordered is-fullwidth">
      <thead>
        <tr>
          <th>Alkaen</th>
          <th>Päättyen</th>
          <th>Hinta / kk</th>
          <th>Lisätietoja</th>
          <th>Yhteystiedot</th>
          <th v-if="$store.state.authUser"></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="Object.keys(data).length">
          <tr v-for="(value, key) in data" :key="key">
            <td data-title="Alkaen">{{ value.from }}</td>
            <td data-title="Päättyen">{{ value.to }}</td>
            <td data-title="Hinta / kk">{{ value.price }} €</td>
            <td data-title="Lisätietoja">{{ value.info || '-' }}</td>
            <td data-title="Yhteystiedot">
              <p>
                <b>
                  {{ value.name }}
                </b>
              </p>
              <p>{{ value.phone }}</p>
              <p>{{ value.email }}</p>
            </td>
            <th v-if="$store.state.authUser" class="has-text-centered">
              <font-awesome-icon
                :icon="['fas', 'trash']"
                @click="deleteAdvert(key)"
              />
            </th>
          </tr>
        </template>
        <tr v-else>
          <td
            :colspan="$store.state.authUser ? 6 : 5"
            class="has-text-centered"
          >
            Ilmoituksia ei vielä ole
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    async deleteAdvert(key) {
      console.log(key)
      try {
        const result = await this.$axios.$post('/api/advert/delete', {
          advert: key
        })
        if (result.success) {
          this.$emit('refresh')
        }
      } catch (error) {
        if (error.response.status === 401) {
          await this.$store.dispatch('logout')
          this.$router.push('/login')
        }
      }
    }
  }
}
</script>

<style lang="scss">
.table-rent {
  .table thead th {
    background-color: $primary;
    color: white;
  }
}
</style>
