export default {
  methods: {
    /**
     * Handles server field errors on application
     *
     * @param {object} errors Object containing error with error message
     */
    handleFieldError(errors) {
      errors.forEach(field => {
        try {
          const validatorField = this.$validator.fields.find({
            name: field.param
          })
          validatorField.setFlags({ invalid: true })
          this.errors.add({
            field: field.param,
            msg: field.msg,
            id: validatorField.id,
            scope: validatorField.scope
          })
        } catch (error) {
          this.$logger.warning(error, {
            metaData: {
              field: field.param,
              errorBag: errors
            }
          })
        }
      })
    }
  }
}
